import React from "react";
import "./Footer.scss";

export const Footer = ({textAlign}) => {
    return (
      <div className="footer">
        <div className="footer-div" style={{ textAlign }}>
          ©2022 Ghirardelli Chocolate Company
        </div>
      </div>
    );
};
