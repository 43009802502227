import axios from 'axios'
import utils from './index'

const headers = () => ({
  headers: {
    // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImxvZ2luQGdoaXJhcmRlbGxpLmNvbSIsIklEIjoiQzM3ODlDQzMtMTg1Mi00OTAyLUIzMjgtODA0MDA3QUYyRkUyIiwicm9sZSI6Ikdsb2JhbCBBZG1pbiIsIm5iZiI6MTY0ODYzNzU4NCwiZXhwIjoxNjQ4NzIzOTg0LCJpYXQiOjE2NDg2Mzc1ODR9.d6TY3SaHPN4f1wtrakSwGCk-Hn2JWq4NbyB3pI91AFw",
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

export async function uploadImage(payload) {
  try {
    const url = utils.hostURL("Kiosk/UploadCustomPhoto");
    const res = await axios.post(url, payload, headers());
    return { success: true, data: res.data  }
  } catch(e) {
    return {success: false, message: e.response.data.title || "Something went wrong"}
  }
}