import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Preview from "./Preview";
import axios from "axios";
import { LoadingOutlined } from '@ant-design/icons';
import SelectImage from "./SelectImage";
import PreviewModel from "../previewModel";
import { uploadImage } from "../../utils/_data";
import "./index.scss";

const queryString = require('query-string');
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UploadImage = () => {
  const {kioskId, screenId, lng} = queryString.parse(window.location.search);

  const {i18n} = useTranslation();
  const [loader, setLoader] = useState(null);
  const [image, onSelectImage] = useState();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [errMessage, setErrMessage] = React.useState("");
  const localLng = localStorage.getItem('i18nextLng');

  useEffect(async () => {
    if (["en", "es"].includes(localLng)) {
      if ((localLng !== "undefined") || (localLng !== undefined)) {
        await i18n.changeLanguage(localLng);
      } else {
        await i18n.changeLanguage(lng);
      }
    } else {
      await i18n.changeLanguage('en');
    }
  }, [lng]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onUpload = async () => {
    setLoader(antIcon);
    const formBody = new FormData();
    formBody.append('KioskId', kioskId);
    formBody.append('ScreenId', screenId);
    formBody.append('Image', image.originalFile);
    const res = await uploadImage(formBody);
    if (res.success){
      setLoader(null);
      window.location.href = "/";
    } else {
      if(localLng === "es") {
        axios({
          url: `https://api.gccpickandmix.com/api/v1/Kiosk/TranslateText?text=${res.message}&fromLangCode=${"en"}&toLangCode=${localLng}`,
          method: "GET"
        }).then((res) => {
              if (res?.data?.message) {
                setErrMessage(res?.data?.message);
              } else {
                setErrMessage(res?.data);
              }
            })
            .catch((err) => {
              console.log("err---->",err)
            });
        setLoader(null);
        setIsModalVisible(true);
      } else {
        setErrMessage(res.message);
      }
    }
  };

  return (
    <>
      <PreviewModel
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalVisible={isModalVisible}
          message={errMessage}
      />
      {image ?
          <Preview selectedImage={image} onSelectImage={onSelectImage} onUpload={onUpload} loader={loader} />
          : <SelectImage onSelectImage={onSelectImage} isSelectEnable={kioskId && screenId}/>
      }
    </>
  );
};
export default UploadImage;
