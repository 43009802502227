import React, { useState } from 'react';
import Webcam from "react-webcam";

const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user"
};

export const WebcamCapture = ({ onSelectImage }) => {

    const [image,setImage]=useState('');
    const webcamRef = React.useRef(null);


    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            const contentType = 'image/jpeg';
            const b64Data = imageSrc.replace(/^data:image\/[a-z]+;base64,/, "");


            const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, {type: contentType});
                return blob;
            }
            const blob = b64toBlob(b64Data, contentType);

            onSelectImage({
                displayImage: URL.createObjectURL(blob),
                originalFile: blob
            });
        });


    return (
        <div className="webcam-container">
            <div className="webcam-img">
                {!image ? <Webcam
                    audio={false}
                    height={350}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={350}
                    videoConstraints={videoConstraints}
                /> : <img src={image} alt="cam-image" height={350} width={350} />}
            </div>
            <div>
                <div className="d-flex justify-content-center mb-4">
                    <button onClick={(e) => {
                        e.preventDefault();
                        capture();
                    }} className="customizing-take-button d-flex justify-content-center">
                        TAKE A PHOTO
                    </button>
                </div>
                {/*{image ?
                    <div className="d-flex justify-content-center mb-4">
                        <button onClick={(e) => {
                            e.preventDefault();
                            capture();
                        }} className="customizing-take-button d-flex justify-content-center">
                            RETAKE PHOTO
                        </button>
                    </div> :
                    <div className="d-flex justify-content-center mb-4">
                        <button onClick={(e) => {
                            e.preventDefault();
                            capture();
                        }} className="customizing-take-button d-flex justify-content-center">
                            CAPTURE
                        </button>
                    </div>
                }*/}
            </div>
        </div>
    );
};