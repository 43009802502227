const config = {
  production: {
    BASE_URL: "https://api.gccpickandmix.com/api/v1/",
    CONNECTION_HUB: "https://api.gccpickandmix.com/customimagehub",
  },
  staging: {
    BASE_URL:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/api/v1/",
    CONNECTION_HUB:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/customimagehub",
  },
  development: {
    BASE_URL:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/api/v1/",
    CONNECTION_HUB:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/customimagehub",
  },
};
const API_CONFIG = config[process.env.REACT_APP_ENV || "staging"];

const utils = {
  hostURL: (url) => `${API_CONFIG.BASE_URL}${url}`,
  signalR: API_CONFIG.CONNECTION_HUB,
};

export default utils;
