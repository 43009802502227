import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import logo from "../../assets/images/logo.svg";
import { WebcamCapture } from "./WebCam";
import "./index.scss";

const SelectImage = ({ onSelectImage, isSelectEnable }) => {
  const [isWebCam, setWebCamLive] = useState(false);
  const { t } = useTranslation();
  const onSelect = (e) => {
    const imageURL = e.target.files[0];
    var reader = new FileReader();

    reader.addEventListener(
      "load",
      function() {
        // preview.src = reader.result;
        crop(reader.result, 1 / 1).then((canvas) => {
          croppedURL = canvas.toDataURL();
          const image = canvas.toDataURL("image/png");
          const DataURIToBlob = (dataURI) => {
            const splitDataURI = dataURI.split(",");
            const byteString =
              splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
            const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++)
              ia[i] = byteString.charCodeAt(i);

            return new Blob([ia], { type: mimeString });
          };
          const file = DataURIToBlob(image);
          if (e.target.files && e.target.files.length > 0) {
            onSelectImage({
              displayImage: croppedURL,
              originalFile: file,
            });
          }
        });
      },
      false
    );
    if (imageURL) {
      reader.readAsDataURL(imageURL);
    }

    let croppedURL;

    function crop(url, aspectRatio) {
      return new Promise((resolve) => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
          // let's store the width and height of our image
          const inputWidth = inputImage.naturalWidth;
          const inputHeight = inputImage.naturalHeight;
          // get the aspect ratio of the input image
          const inputImageAspectRatio = inputWidth / inputHeight;

          // if it's bigger than our target aspect ratio
          let outputWidth = inputWidth;
          let outputHeight = inputHeight;
          if (inputImageAspectRatio > aspectRatio) {
            outputWidth = inputHeight * aspectRatio;
          } else if (inputImageAspectRatio < aspectRatio) {
            outputHeight = inputWidth / aspectRatio;
          }

          // calculate the position to draw the image at
          const outputX = (outputWidth - inputWidth) * 0.5;
          const outputY = (outputHeight - inputHeight) * 0.5;

          // create a canvas that will present the output image
          const outputImage = document.createElement("canvas");

          // set it to the same size as the image
          outputImage.width = outputWidth;
          outputImage.height = outputHeight;

          // draw our image at position 0, 0 on the canvas
          const ctx = outputImage.getContext("2d");
          ctx.drawImage(inputImage, outputX, outputY);
          resolve(outputImage);
        };

        // start loading our image
        inputImage.src = url;
      });
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        <div className="customizing-main">
          <div className="customizing-main-top d-flex justify-content-center">
            <img src={logo} alt="logo" width={400} height={151} />
          </div>
          <div className="customizing-main-center mt-5 mb-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="customizing-main-center-text-one mb-4">
                {t("common.uploadImg")}
              </div>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <div className="customizing-pick-button  d-flex justify-content-center">
                <label className="d-flex align-items-center">
                  <input
                    disabled={!isSelectEnable}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={onSelect}
                  />
                  {t("common.libraryPick")}
                </label>
              </div>
            </div>
            <div className="customizing-main-center-text-three align-items-center d-flex justify-content-center mb-4">
              {t("common.textOr")}
            </div>
            {isWebCam ? (
              <WebcamCapture onSelectImage={onSelectImage} />
            ) : (
              <div className="d-flex justify-content-center mb-4">
                <button
                  disabled={!isSelectEnable}
                  className="customizing-take-button align-items-center d-flex justify-content-center"
                  onClick={() => setWebCamLive(true)}
                >
                  {t("common.takePhoto")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectImage;
