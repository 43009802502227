import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import logo from "../../assets/images/logo.svg";
import random from "../../assets/images/person.jpg";
import { Spin } from 'antd';
import "./index.scss";

const Preview = ({ selectedImage, onSelectImage, onUpload, loader }) => {
    const { t } = useTranslation();

    return (
        <div className="preview-main">
            <div className="preview-header text-center">
                <img src={logo} alt="logo" width={400} height={151} />
            </div>
            <div className="preview-main-center mt-5 mb-5">
                <div className="d-flex align-items-center justify-content-center mb-5">
                    <div className="preview-main-center-text-one">{t("common.uploadImg")}</div>
                </div>
                <div className="preview-image d-flex justify-content-center">
                    <img src={selectedImage.displayImage || random} alt="logo" width={350} height={350} />
                </div>
                <div className="preview-button d-flex justify-content-center align-items-baseline">
                    <div className="preview-button-upload d-flex justify-content-center  ml-4 mt-5 mb-4">
                        <button
                            className="buttonCustom uplod-button"
                            onClick={onUpload}
                        >
                            <Spin className='spin-loader' indicator={loader} />
                            {t("common.upload")}
                        </button>
                    </div>
                    <div className="preview-button-back d-flex justify-content-center  mb-4">
                        <button className="buttonCustom back-button" onClick={() => onSelectImage(null)}> {t("common.back")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Preview;
