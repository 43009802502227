import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import logo from "../../assets/images/Group 8.svg";
import leftLogo from "../../assets/images/modal-bg-left.png";
import rightLogo from "../../assets/images/modal-bg-right.png";
import "./index.scss";
const PreviewModel = ({ handleCancel, handleOk, isModalVisible, message }) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Modal
          className="main-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={100} height={100} />
          </div>
          <div
            className="model-right-logo position-absolute"
            style={{ right: "0px" }}
          >
            <img src={rightLogo} alt="" width={100} height={100} />
          </div>
          <div className="preview-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="preview-model-main">
            <div className="preview-logo" />
            <div className="preview-model-title">
              {t("common.opps")}
            </div>
            <div className="preview-model-details">
              {message || "Something went wrong"}
            </div>
            <div className="preview-model-botton">
              <button className="buttonCustom mt-3" onClick={handleCancel}>
                {t("common.gotit")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PreviewModel;
