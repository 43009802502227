import React from "react";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common/styles/global.scss";
import Main from "./pages/Main";

const App = () => (
    <React.Suspense fallback={<div>Loading</div>}>
      <Main />
    </React.Suspense>
);

export default App;
