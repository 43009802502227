import React, {useEffect} from "react";
import {Route, Switch, BrowserRouter} from "react-router-dom";
import UploadImage from "./UploadImage";
import {Footer} from "../common/components/Footer";
import "./Main.scss";

const Main = () => {
    return (
        <>
        <div className="app">
          <main className={`d-flex`}>
            <div className={`w-100 h-100 mainContent d-flex`}>
              <BrowserRouter>
                <Switch>
                  <Route exact path={`/`} component={UploadImage} />
                </Switch>
              </BrowserRouter>
            </div>
            <Footer textAlign="left" />
          </main>
        </div>
      </>
    );
};

export default Main;
